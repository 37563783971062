import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import HistorialIcon from './icons/historial';
import DeleteButton from './deleteAndLikes/deleteButomTrainedHistorial';
import Verdetalle from './detalleEquipos';
import './historial.css'; // Estilos CSS para el historial
import useClickOutside from './modulos-propios/useClickOutside'; 



const FETCH_POSTS_QUERY_USER_LINK = gql`
  query getUsersEquiposCreados($username: String!) {
    getUsersEquiposCreados(username: $username) {
      id
      nombres
      informacion
      createdAt
    }
  }
`;

const FETCH_EQUIPOS_CREADOS_QUERY = gql`
  query($username: String!) {
    getUsersEquiposCreados(username: $username) {
      id
      nombres
      informacion
      createdAt
    }
  }
`;

function FilteredPosts({ refetch, user }) {
  const username = user.replace(/[^a-zA-Z0-9]/g, '');
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({});
  const dropdownRefs = useRef({});
  useClickOutside(showDropdown, setShowDropdown);
  const [isModalDetalleOpen, setIsModalDetalleOpen] = useState(false);






  const { loading, error, data, refetch: queryRefetch } = useQuery(FETCH_POSTS_QUERY_USER_LINK, {
    variables: { username }
  });

  const handleDropdownToggle = (postId) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [postId]: !prevState[postId]
    }));
  };

  const handleClickOutside = (event) => {
    Object.keys(dropdownRefs.current).forEach((key) => {
      if (dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)) {
        setDropdownStates(prevState => ({ ...prevState, [key]: false }));
      }
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);




  const { postId } = useParams();
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (refetch) {
      setCurrentPage(0);
      queryRefetch();
    }
  }, [refetch, queryRefetch]);

  useEffect(() => {
    if (data && postId) {
      const posts = data.getUsersEquiposCreados || [];
      const groupedPosts = classifyPosts(posts);
      const categories = Object.keys(groupedPosts).filter(category => groupedPosts[category].length > 0);
      const chunkedPosts = categories.map(category => ({
        category,
        posts: groupedPosts[category]
      }));

      for (let i = 0; i < chunkedPosts.length; i++) {
        if (chunkedPosts[i].posts.some(post => post.id === postId)) {
          setCurrentPage(i);
          break;
        }
      }
    }
  }, [data, postId]);

  if (loading) {
    return (
      <div className="spinner-container">
        <div className="spinner">
          <img src='/images/logoFinalDark.png' alt="loading" />
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  

  


  

  const posts = data?.getUsersEquiposCreados || [];

  const classifyPosts = (posts) => {
    const now = new Date();
    const today = new Date(now.setHours(0, 0, 0, 0));
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const thisWeek = new Date(today);
    thisWeek.setDate(today.getDate() - 7);
    const fourteenDaysAgo = new Date(today);
    fourteenDaysAgo.setDate(today.getDate() - 14);
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);
    const sixtyDaysAgo = new Date(today);
    sixtyDaysAgo.setDate(today.getDate() - 60);
    const ninetyDaysAgo = new Date(today);
    ninetyDaysAgo.setDate(today.getDate() - 90);
    const oneHundredEightyDaysAgo = new Date(today);
    oneHundredEightyDaysAgo.setDate(today.getDate() - 180);
    const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(today.getFullYear() - 1);

    const groups = {
      'Hoy': [],
      'Ayer': [],
      'Esta semana': [],
      'De 7 a 14 días': [],
      'De 15 a 30 días': [],
      'De 31 a 60 días': [],
      'De 61 a 90 días': [],
      'De 91 a 180 días': [],
      'De 181 a 364 días': [],
      'Más de 1 año': []
    };

    posts.forEach(post => {
      const createdAt = new Date(post.createdAt);
      if (createdAt >= today) {
        groups['Hoy'].push(post);
      } else if (createdAt >= yesterday) {
        groups['Ayer'].push(post);
      } else if (createdAt >= thisWeek) {
        groups['Esta semana'].push(post);
      } else if (createdAt >= fourteenDaysAgo && createdAt < thisWeek) {
        groups['De 7 a 14 días'].push(post);
      } else if (createdAt >= thirtyDaysAgo && createdAt < fourteenDaysAgo) {
        groups['De 15 a 30 días'].push(post);
      } else if (createdAt >= sixtyDaysAgo && createdAt < thirtyDaysAgo) {
        groups['De 31 a 60 días'].push(post);
      } else if (createdAt >= ninetyDaysAgo && createdAt < sixtyDaysAgo) {
        groups['De 61 a 90 días'].push(post);
      } else if (createdAt >= oneHundredEightyDaysAgo && createdAt < ninetyDaysAgo) {
        groups['De 91 a 180 días'].push(post);
      } else if (createdAt >= oneYearAgo && createdAt < oneHundredEightyDaysAgo) {
        groups['De 181 a 364 días'].push(post);
      } else {
        groups['Más de 1 año'].push(post);
      }
    });

    return groups;
  };

  const groupedPosts = classifyPosts(posts);
  const categories = Object.keys(groupedPosts).filter(category => groupedPosts[category].length > 0);
  const chunkedPosts = categories.map(category => ({
    category,
    posts: groupedPosts[category]
  }));

  const nextPage = () => {
    if (currentPage < chunkedPosts.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
  <div className="posts-container">
        {chunkedPosts[currentPage]?.posts.map((post, index) => (
          <div key={index} className="post-item" ref={el => dropdownRefs.current[post.id] = el}>
          
            <Link
              to={`/estadoEquipo/${post.id}`}
              className={`post-link ${post.id === postId ? 'active' : ''}`}
            >
              <HistorialIcon size={13} />
              {' ' + (post.nombres.length > 19 ? post.nombres.substring(0, 19).toLowerCase() + '..' : post.nombres.toLowerCase())}
            </Link>
            {post.id === postId && (
              <button className="dropdownH-toggle" onClick={() => handleDropdownToggle(post.id)}>
                <span className="vertical-dots"></span>
              </button>
            )}
            {dropdownStates[post.id] && (
              <div id="dropdownH" className="dropdownH">
                <div className="dropdownH-menu">
                  {/*{post.id === postId && <DeleteButton postId={post.id} index={index} username={post.linkOwner} refetch={refetch} />}*/}
                  <button>
                  <a  onClick={() => setIsModalDetalleOpen(true)}>Ver detalle</a>
                     <Verdetalle isOpen={isModalDetalleOpen} onClose={() => setIsModalDetalleOpen(false)} titulo={post.nombres} resumen={post.informacion}/>
                  </button>
                </div>
              </div>
            )}
          </div>
          
        ))}
      </div>


      <div className="pagination-controls">
        <button onClick={prevPage} disabled={currentPage === 0}>
          <FaChevronLeft size={9} /><FaChevronLeft size={9} />
        </button>
        <span>{chunkedPosts[currentPage]?.category} - {currentPage + 1} / {chunkedPosts.length}</span>
        <button onClick={nextPage} disabled={currentPage === chunkedPosts.length - 1}>
          <FaChevronRight size={9} /><FaChevronRight size={9} />
        </button>
      </div>
    </div>
  );
}

export default FilteredPosts;




