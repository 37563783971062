import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import './ModalDetalle.css';

const Modal = ({ isOpen, onClose, titulo, resumen }) => {
  const handleClose = () => {
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="modal-overlay-detalles" onClick={handleClose}></div>
      <div className="modal-wrapper-detalles">
        <div className="modal-content-detalles">
          
          <div className="modal-header-detalles">
            Detalle del Equipo
            <button className="modal-header-detalles-close">
                <AiOutlineClose onClick={handleClose} />
            </button>
          </div>
          <div className="modal-body-detalles">
            <h3>{titulo}</h3>
               <>
               <h4>Información</h4>
               <p>{resumen}</p>
             </> 
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
