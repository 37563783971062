import React, { useState } from 'react';

const IconViewDashboard = (index) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <>
      <span
        style={{ cursor: 'pointer', color: 'grey', padding: '15px 15px' }}
        onMouseEnter={() => setShowTooltip(index)}
        onMouseLeave={() => setShowTooltip(null)}
      >
        <svg viewBox="0 0 24 24" fill="currentColor" width="16" height="16">
          <defs>
            <style>
              {`
                .cls-1 {
                  stroke-width: 0px;
                }
                .cls-1, .cls-2 {
                  fill: none;
                }
                .cls-2 {
                  stroke: #000;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 2px;
                }
              `}
            </style>
          </defs>
          <path d="M13 3v6h8V3m-8 18h8V11h-8M3 21h8v-6H3m0-2h8V3H3v10z" />
        </svg>
      </span>
    </>
  );
};

export default IconViewDashboard;

