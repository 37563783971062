import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import './SurveyComponent.css';
import './LoadingEffect.css';
import HelpEncuesta from '../helpEncuestaLink';

const FETCH_POST_LINK_MAIL = gql`
query ($postId: ID!) {
    getPosLinkMail(postId: $postId) {
        id
        username
        estado
        solicitanteLinkid
        linkOwner
        emailOwner
        idPuestolaboral
        createdAt
        comments {
          id
          body
          respuesta
        }
    }
}`;

const ACEPTAR_PERMISO = gql`
mutation($emailOwner: String!, $linkOwner: String!, $linkID: String!, $solicitanteLinkid: String!){
    conductualLINKcompartir(emailOwner: $emailOwner, linkOwner: $linkOwner, linkID: $linkID, solicitanteLinkid: $solicitanteLinkid)
}`;

const SurveyComponent = () => {
    const [loadingMessage, setLoadingMessage] = useState(<span className='loading-text'>Cargando...</span>);
    const [destinatario, setDestinatario] = useState('');
    const [estado, setEstado] = useState('');
    const [emailOwner, setEmailOwner] = useState('');
    const [solicitanteLinkid, setSolicitanteLinkid] = useState('');
    const [linkOwner, setLinkOwner] = useState('');
    const [linkID, setLinkID] = useState('');
    const [showLoading, setShowLoading] = useState(false);

    const { postId } = useParams();
    const { loading, data } = useQuery(FETCH_POST_LINK_MAIL, {
        variables: { postId },
        skip: !postId,
    });

    const [acceptPermission] = useMutation(ACEPTAR_PERMISO);

    useEffect(() => {
        if (data && data.getPosLinkMail) {
            const post = data.getPosLinkMail;
            setDestinatario(post.username);
            setEstado(post.estado);
            setEmailOwner(post.emailOwner);
            setSolicitanteLinkid(post.solicitanteLinkid);
            setLinkOwner(post.linkOwner);
            setLinkID(postId);
        }
    }, [data]);

    const handleSubmitAceptar = async () => {
        setShowLoading(true);
        setLoadingMessage(<span className='loading-text'>Procesando información! Por favor espere.</span>);
        try {
            await acceptPermission({
                variables: {
                    emailOwner,
                    solicitanteLinkid,
                    linkOwner,
                    linkID,
                },
            });
            setEstado('aceptado');
        } catch (error) {
            console.error('Error al aceptar la solicitud:', error);
        }
        setShowLoading(false);
    };

    return (
        <div>
            {loading || showLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{loadingMessage}</div>
            ) : (
                estado === 'pendiente' ? (
                    <div className="contexto-link">
                        <div className="survey-container">
                            <h3>Solicitud de resultados conductuales e información laboral</h3>
                            <h5>Hola {destinatario}</h5>
                            <p className="warningLink">Has recibido una solicitud para compartir los resultados de tu test conductual y toda tu información laboral. Si aceptas, compartiremos esta información con el solicitante.</p>
                            <div className="survey-navigation">
                                <button onClick={handleSubmitAceptar} className="navi-button">Aceptar</button>
                                <button className="navi-button" disabled={true}>Rechazar</button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="contexto-link">
                        <div className="survey-container">
                            <p className='loading-text'>El enlace ya ha sido utilizado. Puedes cerrar el navegador.</p>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default SurveyComponent;

