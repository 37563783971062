import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CerrarSide from '../icons/arrowLeft';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

const FETCH_USER_TYPES_QUERY = gql`
  query eneagramSubtypeInfo($username: String!, $idUserlink: String!) {
    eneagramSubtypeInfo(username: $username, idUserlink: $idUserlink) {
      id
      description
      subtype
      subtypeDescription
      strengths
      weaknesses
      missingSkills
      recommendedAssistants {
        type
        reason
      }
      successRate
    }
  }
`;

const FETCH_USER_DISC_QUERY = gql`
query getDISCProfileInfo($username: String!, $idUserlink: String!) {
    getDISCProfileInfo(username: $username, idUserlink: $idUserlink) {
      caracteristicas_para_escalar
      debilidades
      necesidades_para_100
      tasa_exito
      asistentes_recomendados {
        perfil
        razon
      }
      gestion_adecuacion_asistentes
      desafios_equipo_asistentes
      consideraciones_asistentes
      temores_comunes
      temores_mas_comunes_asistentes
    }
  }
`;

function filterTypename(data) {
  if (Array.isArray(data)) {
    return data.map(item => filterTypename(item));
  } else if (typeof data === 'object' && data !== null) {
    const { __typename, ...rest } = data;
    return Object.keys(rest).reduce((acc, key) => {
      acc[key] = filterTypename(rest[key]);
      return acc;
    }, {});
  }
  return data;
}

const UserDash = () => {
  const navigate = useNavigate();
  const [expandedSections, setExpandedSections] = useState({});
  const [expandedAssistants, setExpandedAssistants] = useState({});
  const [expandedProfiles, setExpandedProfiles] = useState({});
  const [fetchVariables, setFetchVariables] = useState(null);
  const location = useLocation();
  const { username, idUserlink } = location.state || {};

  const styles = {
    dashboardContainer: {
      padding: "20px",
      paddingBottom: "30px",
      maxWidth: "1200px",
      margin: "0 auto",
      background: "#fff",
      borderRadius: "8px",
      overflowY: "auto",
      maxHeight: "90vh",
      maxWidth: "80vh",
      position: "relative",
    },
    closeButton: {
      position: "fixed",
      top: "15px",
      left: "40px",
      background: "transparent",
      color: "black",
      border: "none",
      borderRadius: "5px",
      padding: "5px 10px",
      cursor: "pointer",
      zIndex: 1000,
    },
    gridContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: "20px",
    },
    section: {
      marginBottom: "20px",
    },
    card: {
      padding: "15px",
      background: "#fff",
      borderRadius: "8px",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
      cursor: "pointer",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    content: {
      marginTop: "10px",
      color: "#395560",
      fontSize: "14px",
    },
    paragraphCard: {
      padding: "10px",
      background: "#f5f5f5",
      borderRadius: "5px",
      marginBottom: "10px",
      boxShadow: "0 0 1px rgba(0, 0, 0, 0.1)",
      cursor: "pointer",
    },
    discTitle: {
      fontSize: "14px",
      fontWeight: "400",
    },
  };



  useEffect(() => {
    if (username && idUserlink) {
      setFetchVariables({ username, idUserlink });
    }
  }, [username, idUserlink]);

  const { loading, error, data } = useQuery(FETCH_USER_TYPES_QUERY, {
    variables: fetchVariables,
    skip: !fetchVariables,
  });

  const { loading: discLoading, error: discError, data: discData } = useQuery(FETCH_USER_DISC_QUERY, {
    variables: fetchVariables,
    skip: !fetchVariables,
  });

  if (loading || discLoading)  return  <div className="spinner-container">
  <div className="spinner">
    <img src='/images/logoFinalDark.png' alt="loading" />
  </div>
</div>;
  ///if (error || discError) return <p>Error: {error ? error.message : discError.message}</p>;

  if (error || discError) {
    return (
      <>
      <button 
        style={styles.closeButton} 
        onClick={() => navigate(`/dashboard/${fetchVariables?.username}?email=${fetchVariables?.email}`)}
      >
        <CerrarSide />
      </button>
      <div style={{  display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <p>
          {error ? 'No hay Dashboard para Equipos por el momento...' : 'No hay Dashboard para Equipos por el momento...'}
        </p>
      </div>
    </>
      
    );
  }

  const eneagramData = data?.eneagramSubtypeInfo || [];
  const discProfileData = discData?.getDISCProfileInfo ? filterTypename(discData.getDISCProfileInfo) : {};

  const toggleProfile = (profileId) => {
    setExpandedProfiles((prev) => ({
      ...prev,
      [profileId]: !prev[profileId],
    }));
  };

  const toggleSection = (profileId, section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [profileId]: {
        ...prev[profileId],
        [section]: !prev[profileId]?.[section],
      },
    }));
  };

  const toggleAssistant = (profileId, index) => {
    setExpandedAssistants((prev) => ({
      ...prev,
      [profileId]: {
        ...prev[profileId],
        [index]: !prev[profileId]?.[index],
      },
    }));
  };

 

  return (
    <div style={styles.dashboardContainer}>
      <button style={styles.closeButton} onClick={() => navigate(`/dashboard/${fetchVariables?.username}?email=${fetchVariables?.email}`)}><CerrarSide /></button>
      <h2>Habilidades de Escalabilidad</h2>
      <p>Haz clic en los títulos para ver más detalles:</p>

      <div style={styles.gridContainer}>
        {eneagramData.map((profile, index) => (
          <div
            key={profile.id}
            onClick={() => toggleProfile(profile.id)}
            style={styles.card}
          >
            <p>{index === 0 ? 'El lado emocional' : index === 1 ? 'El lado instintivo' : 'El lado mental'}</p>
            {expandedProfiles[profile.id] && (
              <div style={styles.content}>
                <p>
                  <strong style={{ color: "black" }}>Fortalezas:</strong> {profile.strengths.join(", ")}
                </p>
                <p>
                  <strong style={{ color: "black" }}>Debilidades:</strong> {profile.weaknesses.join(", ")}
                </p>
                <p>
                  <strong style={{ color: "black" }}>Habilidades Faltantes:</strong> {profile.missingSkills.join(", ")}
                </p>
                <p>
                  <strong style={{ color: "black" }}>Recomendación de asistentes para alcanzar el 100%:</strong> {profile.recommendedAssistants.map((assistant) => `${assistant.type} - ${assistant.reason}`).join(", ")}
                </p>
                <p>
                  <strong style={{ color: "black" }}>Tasa de Éxito:</strong> {profile.successRate}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* DISC Section updated to use fetched data */}
      <div style={{ marginTop: "40px" }}>
        <h3>Eslalabilidad enfoque DISC</h3>
        <div style={{ ...styles.gridContainer, gridTemplateColumns: "repeat(2, 1fr)" }}>
          {Object.keys(discProfileData).sort((a, b) => {
            const order = [
              'necesidades_para_100',
              'temores_comunes',
              'consideraciones_asistentes',
              'temores_mas_comunes_asistentes'
            ];
            return order.indexOf(a) - order.indexOf(b);
          }).map((section, index) => (
            section === "necesidades_para_100" || section === "temores_comunes" ? (
              <div
                key={index}
                style={{
                  ...styles.card,
                  gridColumn: "span 2",
                }}
              >
                <div onClick={() => toggleSection("DISC", section)}>
                  <h3 style={styles.discTitle}>{section.replace(/_/g, " ").toUpperCase()}</h3>
                </div>
                {expandedSections["DISC"]?.[section] && (
                  <div style={styles.content}>
                    {section === "necesidades_para_100" ? (
                      <>
                        <div style={styles.paragraphCard}>
                          <p><strong>Tasa de Éxito Actual:</strong> {discProfileData.tasa_exito}</p>
                        </div>
                        <div style={styles.paragraphCard}>
                          <p><strong>Necesidades para llegar al 100%:</strong> {discProfileData.necesidades_para_100.join(", ")}</p>
                        </div>
                      </>
                    ) : (
                      discProfileData[section].map((item, idx) => (
                        <div key={idx} style={styles.paragraphCard}>
                          <p>{item}</p>
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div
                key={index}
                style={styles.card}
              >
                <div onClick={() => toggleSection("DISC", section)}>
                  <h3 style={styles.discTitle}>{section.replace(/_/g, " ").toUpperCase()}</h3>
                </div>
                {expandedSections["DISC"]?.[section] && section === "asistentes_recomendados" && (
                  <div style={styles.content}>
                    {discProfileData[section].map((asistente, idx) => (
                      <div
                        key={idx}
                        style={styles.paragraphCard}
                        onClick={() => toggleAssistant("DISC", idx)}
                      >
                        <h4>{asistente.perfil.toUpperCase()}</h4>
                        {expandedAssistants["DISC"]?.[idx] && <p>{asistente.razon}</p>}
                      </div>
                    ))}
                  </div>
                )}
                {expandedSections["DISC"]?.[section] && section !== "asistentes_recomendados" && (
                  <div style={styles.content}>
                    {Array.isArray(discProfileData[section]) ? (
                      discProfileData[section].map((item, idx) => (
                        <div key={idx} style={styles.paragraphCard}>
                          <p>{typeof item === "string" ? item : JSON.stringify(item)}</p>
                        </div>
                      ))
                    ) : (
                      <div style={styles.paragraphCard}>
                        <p>{discProfileData[section]}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserDash;

  