import React, { useState, useRef, useEffect } from 'react';
import '../App.css'; // Estilos CSS para el chat
import { 
    FaArrowDown, 
    FaDownload, 
    FaCheckCircle,
    FaRedo, 
    FaCaretDown, 
    FaArrowRight, 
    FaSignInAlt, 
    FaSignOutAlt} from 'react-icons/fa'; // Importa los iconos necesarios
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import Terminos from './terminosDeUso';
import Suscripciones from './suscripciones';
import './Modal.css';
import FilteredPosts from './historialTemporal'; //// historial
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import client from '../apolloClient';
import { generatePdf } from './modulos-propios/generatePDF';
import { StreamingText } from './modulos-propios/streamingText';
import useAutoScroll from './modulos-propios/useSingleAutoScroll'; // Importa el módulo useAutoScroll
import useScrollToBottom from './modulos-propios/useSingleScrolledToBottom'; // Importa el módulo useScrollToBottom
import useClickOutside from './modulos-propios/useClickOutside'; // Importa el módulo useClickOutside
import Nuevo from './icons/nuevo';
import Copy from './icons/copy';
import AbrirSide from './icons/arrowLeft';
import CerrarSide from './icons/arrowRigth';
import Puntitos from './deleteAndLikes/SpinnerChat';
import UserChat from './userChat/selectUserChat';
///import TextoOrganizado from './render/TextoOrganizado';
///const useradmin = process.env.REACT_APP_ADMIN || process.env.REACT_APP_ADMIN2 || process.env.REACT_APP_ADMIN3


const useradmin = [
  process.env.REACT_APP_ADMIN,
  process.env.REACT_APP_ADMIN2,
  process.env.REACT_APP_ADMIN3,
  process.env.REACT_APP_ADMIN4,
  process.env.REACT_APP_ADMIN5,
  process.env.REACT_APP_ADMIN6
];

const isAdmin = (currentUser) => {
  return useradmin.includes(currentUser);
};

const FETCH_USERCONDUCTUAL_QUERY = gql`
  query($username: String!) {
    getUserconductual(username: $username) {
      id
      username
      disc
      eneagrama
      bigfive
    }
  }
`;


const FETCH_USERCONDUCTUAL_LINK_QUERY = gql`
  query($username: String!) {
    getUsersLinkByUsername(username: $username) {
      id
      nombres
      createdAt
    }
  }
`;



const FETCH_POST_QUERY = gql`
  query($postId: ID!) {
    getPost(postId: $postId) {
      id
      body
      estado
      createdAt
      username
      likeCount
      likes {
        username
      }
      commentCount
      comments {
        id
        body
        respuesta
        createdAt
        username
      }
    }
  }
`;

const SUBMIT_COMMENT_MUTATION = gql`
  mutation($postId: String!, $body: String!,$username: String!, $idUserlink: String!) {
    createComment(postId: $postId, body: $body, username: $username, idUserlink: $idUserlink) {
      id
      comments {
        id
        body
        respuesta
        createdAt
        username
      }
      commentCount
    }
  } 
`;

const CREATE_POST_MUTATION = gql`
  mutation createPost($body: String!, $username: String!, $idUserlink: String!) {
    createPost(body: $body, username: $username, idUserlink: $idUserlink) {
      id
      body
      estado
      createdAt
      username
      likes {
        id
        username
        createdAt
      }
      likeCount
      comments {
        id
        body
        respuesta
        username
        createdAt
      }
      commentCount
    }
  }
`;

const Chat = () => {
  const { postId } = useParams();
  const [showSidebar, setShowSidebar] = useState(true); // Estado para controlar la visibilidad del sidebar
  const [showDropdown, setShowDropdown] = useState(false);
  useClickOutside(showDropdown, setShowDropdown); // Utiliza el módulo useClickOutside
  const [currentChat, setCurrentChat] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuscripcionesOpen, setSuscripcionesOpen] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [selectedUser, setSelectedUser] = useState('Selecionar usuario');
  const [idUserlink, setSidUserlink] = useState('');
  const messagesEndRef = useRef(null);
  const bodymessagesEndRef = useRef(null);
  const isScrolledToBottom = useScrollToBottom(bodymessagesEndRef); // Utiliza el módulo useScrollToBottom
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { loginWithRedirect, logout } = useAuth0();
  const navigate = useNavigate();
  const username = user?.sub?.replace(/[^a-zA-Z0-9]/g, '');

  const location = useLocation();



  const { data: userConductualLinkData, loading: userConductualLinkLoading, error: userConductualLinkError, refetch: refetch } = useQuery(FETCH_USERCONDUCTUAL_LINK_QUERY, {
    variables: { username },
    skip: !isAuthenticated
  });

  const destinatario = location.state?.selectedUser.nombres || '';

  useEffect(() => {
    if (location.state?.refetchNeeded) {
      refetch();
    }
  }, [location.state, refetch]);

  /*
  useEffect(() => {
    if (idUserlinkLocation) {
      selectedUser(destinatario);
    }
  }, [destinatario]);
*/

  const { data: userConductualData, loading: userConductualLoading, error: userConductualError, refetch: refetchUserConductual } = useQuery(FETCH_USERCONDUCTUAL_QUERY, {
    variables: { username , idUserlink},
    skip: !isAuthenticated
  });


  const userConductual = userConductualData?.getUserconductual;



  useEffect(() => {
    if (isAuthenticated) {
      refetch();
    }
  }, [isAuthenticated, refetch]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSidebar(false);
      }
    };

    handleResize(); // Verificar tamaño al cargar la página
    window.addEventListener('resize', handleResize); // Verificar tamaño al cambiar el tamaño de la pantalla
    return () => window.removeEventListener('resize', handleResize); // Limpiar el event listener
  }, []);

  const manualScrollToBottom = () => {
    if (messagesEndRef.current && typeof messagesEndRef.current.scrollIntoView === 'function') {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
    }
  };

  useAutoScroll(messagesEndRef, currentChat);

  useEffect(() => {
    const handleResize = () => {
      manualScrollToBottom();
    };

    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });

    const observer = new MutationObserver(() => {
      manualScrollToBottom();
    });
    const config = { childList: true, subtree: true };

    if (messagesEndRef.current && messagesEndRef.current.parentNode) {
      resizeObserver.observe(messagesEndRef.current.parentNode);
      observer.observe(messagesEndRef.current.parentNode, config);
    }

    return () => {
      if (messagesEndRef.current && messagesEndRef.current.parentNode) {
        resizeObserver.disconnect();
        observer.disconnect();
      }
    };
  }, [currentChat]);

  useEffect(() => {
    // Scroll to bottom when currentChat changes
    manualScrollToBottom();
  }, [currentChat]);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const createPostMutation = () => {
    return client.mutate({
      mutation: CREATE_POST_MUTATION,
      variables: { body: 'Nuevo chat', username:user.sub, idUserlink: idUserlink},
      onCompleted: (data) => {
        console.log('Mutación completada: ', data);
      },
      onError: (error) => {
        console.error('Error en la mutación: ', error);
      }
    });
  }


  
  const callCreatePost = async (refetch) => {
    try {
      const response = await createPostMutation();
      console.log("Llamada a createPostMutation exitosa:", response.data.createPost.id);
      const postId = response.data.createPost.id;
      //await refetch(); // Refetch posts to update the list
      // Redirige a la nueva ruta
      navigate(`/chat/${postId}`);
    } catch (error) {
      console.error("Error al llamar a createPostMutation:", error);
    }
  }

  const handleNewChat = () => {
    callCreatePost();
  };

  const [showInfo, setShowInfo] = useState(false);
  const [showCopyIcon, setShowCopyIcon] = useState(true);

  const handleCopy = () => {
    const chatText = currentChat.map(message => `${message.sender}: ${message.text}`).join('\n');
    navigator.clipboard.writeText(chatText);
    setShowInfo(true);
    setShowCopyIcon(false);
    setTimeout(() => {
      setShowInfo(false);
      setShowCopyIcon(true);
    }, 1500); // Ocultar el mensaje después de 1.5 segundos y volver a mostrar el icono de copia
  };

  const handleRegenerate = () => {
    // Lógica para regenerar la respuesta del chatbot
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar); // Cambia el estado de visibilidad del sidebar
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Evita que el formulario se envíe automáticamente
      sendMessage(e);
    }
  };

  const { loading, error, data } = useQuery(FETCH_POST_QUERY, {
    variables: { postId },
    skip: postId ? false : true, // Skip si postId es undefined al montar el componente
  });

  useEffect(() => {
    if (data && data.getPost) {
      const post = data.getPost;
      if (post && Array.isArray(post.comments)) {
        const messages = post.comments.flatMap(comment => [
          { text: comment.body, sender: 'user', createdAt: comment.createdAt },
          comment.respuesta && { text: comment.respuesta, sender: 'bot', createdAt: comment.createdAt }
        ]).filter(Boolean);
        const messagesToShow = messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        setCurrentChat(messagesToShow);
      }
    }
  }, [data]);

  if (error) return <p>Procesando</p>;

  const sendMessage = () => {
    
    if (messageInput.trim() !== '') {
      setIsStreaming(true);
     // const newMessage = { text: messageInput +<><Puntitos /></>, sender: 'user' };
      const newMessage = {
        text: <>{messageInput}<Puntitos /></> ,
        sender: 'user'
      };
      setCurrentChat([...currentChat, newMessage]);

      client.mutate({
        mutation: SUBMIT_COMMENT_MUTATION,
        variables: { postId: postId, body: messageInput, username: username, idUserlink: idUserlink},
        onError: (error) => {
          console.error('Error al enviar el comentario:', error);
        }
      });
      setMessageInput(''); // Limpiar el input después de enviar el mensaje
      
    }

  };

  




const sampleDataOwner = {
  getUserconductual: userConductual,
};

const userConductualLINK = userConductualLinkData?.getUsersLinkByUsername || [];

const idUserlinkquery = String(userConductual?.id ?? '');


const michat = {
__typename: 'UserConductualLink',
id: idUserlinkquery,
nombres: 'Yo mism@',
createdAt: "2024-11-28T00:08:11.021Z",
};


const sampleData = {
getUsersLinkByUsername: [...userConductualLINK, michat],
};

  const handleNameSelect = (item) => {
    setSelectedUser(item);
    setSidUserlink(selectedUser.id);
    //refetch()
  };

  

  const handleChatNavigation = () => {
    localStorage.clear()
    navigate(`/new/chat`); // Navega a la página del chat
    //setSelectedUser('Selecionar usuario');
  };

  const handleSupervisar = () => {
    localStorage.clear()
    navigate(`/miequipo`)
  };

  return (
    <div className="chat-container">
       
      {/* Mensajes chat */}
      {loading ? (
        <div className="spinner-container">
          <div className="spinner">
            <img src='/images/logoFinalDark.png' alt="loading" />
          </div>
        </div>
      ) : (
        isAuthenticated && (
          <div className="main2">
            <div className="messages" ref={bodymessagesEndRef}>
              {currentChat.map((message, index) => (
                <div key={index} className={`message ${message.sender}`} ref={messagesEndRef}>
                  {message.sender === 'bot' ? (
                    <>
                      <StreamingText text={message.text} setIsStreaming={setIsStreaming} isStreaming={isStreaming} />
                    </>
                  ) : (
                    <>
                      <img src={user.picture} alt={user.name} className='chat-img-user' />
                      <span>{message.text}</span>
                     {/*} <div className="message-options">
                        <button className='pregunta-options-button' onClick={() => handleRegenerate(message.text)}><FaRedo /></button>
                  </div>*/}
                    </>
                  )}
                  {message.sender === 'bot' && (
                    <div className="message-options">
                      <button className='respuesta-options-button' onClick={() => generatePdf(message.text)}><FaDownload /></button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )
      )}
      {/* BARRA PARTE SUPERIOR */}
      <div className="floating-menu">
      <UserChat data={sampleData} onNameSelect={handleNameSelect} initialSearchTerm={destinatario} />
        {/* LOGO --- ISOLOGO */}
        <img src='/images/LOGO-conductualGPT-01.svg' className="floating-menu-logo-2" />
        {/* CONTENIDO LOGIN--LOGOUT Y Menu */}
        <div className="sidebar-buttons">
          {!isAuthenticated && (
            <button onClick={() => loginWithRedirect()}>
              <FaSignInAlt />
              <span>Ingresar</span>
            </button>
          )}
        </div>
        {isAuthenticated && (
          <div className="profile-container">
            <img src={user.picture} alt={user.name} />
            <div id="dropdown" className="dropdown">
              <button onClick={handleDropdownToggle}>
                <FaCaretDown />
              </button>
              {showDropdown && (
                <div className="dropdown-menu">
                     <button>
                        <a className='button-link' href="#" onClick={handleChatNavigation}>Inicio</a>
                     </button>
                  
                      <button>
                         <a className='button-link' href="#" onClick={() => navigate(`/userlinkOwner`)}>Gestionar Links</a>
                      </button>

                      <button>
                         <a className='button-link' href="#" onClick={() => navigate(`/equipos`)}>Crear equipo</a>
                          </button>
                  
                      {isAdmin(user.sub) && ( <button><a className='button-link' href="#" onClick={() => navigate(`/trained`)}>Entrenar</a></button>)}
                      {isAdmin(user.sub) && ( <button><a className='button-link' href="#" onClick={handleSupervisar}>Supervisar</a></button>)}
                  <button>
                    <a className='button-link' href="#" onClick={() => setSuscripcionesOpen(true, setShowSidebar(false))}>Suscripcion</a>
                  </button>
                  <button onClick={() => {
                                   localStorage.clear(); // Borrar todo el localStorage
                                   logout({ logoutParams: { returnTo: window.location.origin } });
                            }}>
                         <span>Salir</span>
                        <FaSignOutAlt />
                      </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {/* Sidebar */}
      {isAuthenticated && (
        <div className={`sidebar ${showSidebar ? 'open' : 'close'}`}>
          <div className="sidebar-toggle" onClick={toggleSidebar}>
            {showSidebar ? <AbrirSide /> : <CerrarSide />}
          </div>
          {/* Contenido del sidebar */}
          <div className="sidebar-content">
            
            <div className="button-container">
              <button onClick={handleNewChat} disabled={loading}>
                <Nuevo size={10} />
                <p>Nuevo chat</p>
              </button>
              <button onClick={handleCopy} disabled={loading} style={{ paddingRight: '110px' }}>
                {showCopyIcon ? <Copy size={15} /> : <FaCheckCircle style={{ color: 'green' }} />}
                <p>{showCopyIcon ? 'Copiar chat' : 'Chat copiado'}</p>
              </button>
            </div>
            <p>Historial</p>
            {!loading && <FilteredPosts refetch={refetch} user={user.sub} idUserlink={idUserlink} />}
          </div>
        </div>
      )}
      {/* Input chat */}
      <div className="main">
        {isAuthenticated && (
          <div className="input-container">
            <input
              type="text"
              placeholder="Hazme una pregunta conductual..."
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={isStreaming} // Desactivar el input si isStreaming es true
            />
            <button onClick={sendMessage}><FaArrowRight size={15} /></button>
          </div>
        )}
        <div className="disclaimer-base-container">
        <p >Conductual-GPT ™ 2024 | by Nerualdinamica LLC</p>
        </div>
      </div>
      {/* Scrolling Button */}
      {isAuthenticated && (
        <>
          {bodymessagesEndRef.current && !isScrolledToBottom && (
            <div className="scroll-to-bottom" onClick={manualScrollToBottom}>
              <FaArrowDown />
            </div>
          )}
        </>
      )}
      <div className="disclaimer-base-container-terminos">
        <a className='button-link' href="#" onClick={() => setIsModalOpen(true)}>Terminos de uso.</a>
        <Terminos isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        <Suscripciones isOpen={isSuscripcionesOpen} onClose={() => setSuscripcionesOpen(false)} />
      </div>
    </div>
  );
};

export default Chat;

