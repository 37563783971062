import React, { useState, useEffect, useRef } from 'react';
import './DropdownEquipo.css'; // Assuming you will style this with CSS
import DropdownIcon from '../icons/Dropdownicon'; // Assuming DropdownIcon is in the same directory
import { useNavigate } from 'react-router-dom';

const UserEquipo = ({ data = {}, onIdesChange}) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [ides, setIdes] = useState([]);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

   // Close dropdown when clicking outside
   useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (data.getUsersLinkByUsername2 && data.getUsersLinkByUsername2.length > 0) {
      const updatedUsers = data.getUsersLinkByUsername2;
      if (JSON.stringify(updatedUsers) !== JSON.stringify(users)) {
        setUsers(updatedUsers);
        setFilteredUsers(updatedUsers); // Actualizar usuarios filtrados al mismo tiempo
      }
    }
  }, [data, users]);

  useEffect(() => {
    if (onIdesChange) {
      onIdesChange(ides);
    }
  }, [ides, onIdesChange]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (user) => {
    if (!ides.includes(user.id)) {
      setIdes([...ides, user.id]);
    }
    setIsOpen(false);
    setSearchTerm('');
    setFilteredUsers(users);
  };

  const handleRemove = (id) => {
    setIdes(ides.filter((userId) => userId !== id));
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const newFilteredUsers = users.filter((user) =>
      user.nombres.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredUsers(newFilteredUsers);
  };

  const usersPerPage = 5;
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const paginatedUsers = filteredUsers.slice(
    currentPage * usersPerPage,
    (currentPage + 1) * usersPerPage
  );

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
     <div className="dropdown-container2" ref={dropdownRef}>
      <div className="dropdown-header2" onClick={toggleDropdown}>
        {"Seleccionar usuario"}
        <DropdownIcon />
      </div>
      {isOpen && (
        <div className="dropdown-list2">
          <input
            type="text"
            placeholder="Buscar destinatario..."
            value={searchTerm}
            onChange={handleSearch}
            className="dropdown-search2"
          />
          {paginatedUsers.map((user) => (
            <div
              key={user.id}
              className="dropdown-item2"
              onClick={() => handleSelect(user)}
            >
              {user.nombres}
            </div>
          ))}
          <div className="pagination-controls2">
            {currentPage > 0 && <button onClick={prevPage}>&lt;</button>}
            {currentPage < totalPages - 1 && <button onClick={nextPage}>&gt;</button>}
          </div>
        </div>
      )}
    </div>
    {ides.length > 0 && (
      <div className="selected-users-container2">
        <div className="selected-users2">
          {ides.map((id) => {
            const user = users.find((user) => user.id === id);
            return (
              <div key={id} className="selected-user2">
                <span className="user-name2">{user?.nombres}</span>
                <span className="remove-user2" onClick={() => handleRemove(id)}>X</span>
              </div>
            );
          })}
        </div>
      </div>
    )}
    </>
  );
};

export default UserEquipo;
